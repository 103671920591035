<template>
  <div>
    <b-row v-if="sections[0]">
      <template v-for="(component, index) in sections[0].components">
        <b-col
          cols="12"
          sm="12"
          :md="component.col"
          :lg="component.col"
          :key="index"
          :style="
            shouldShowComponent(component) ? '' : { display: 'none !important' }
          "
          :ref="component.key"
          class="mb-1"
        >
          <b-form-group class="m-0">
            <div style="margin-bottom: 4px">
              <div class="d-flex justify-content-between">
                <label>{{ component.label }}</label>
                <div>
                  <!-- <b-form-checkbox
                    v-model="component.disabled"
                    button
                    button-variant="outline-primary"
                    size="sm"
                  >
                    {{ component.disabled ? "Enabled" : "Disabled" }}
                  </b-form-checkbox> -->
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger ml-1 cursor-pointer"
                    @click="removedComponent(component, 0)"
                  />
                </div>
              </div>

              <div
                v-if="component.type == 'drag_and_drop'"
                class="d-flex flex-wrap"
                style="gap: 10px !important"
              >
                <template v-for="(file, index) in component.files">
                  <b-badge variant="light-primary" :key="index">
                    <a
                      :href="file.fileapproved"
                      class="text-primary"
                      target="_blank"
                      rel="noopener"
                      v-b-tooltip.hover="file.custom_name"
                      >{{ file.custom_name | limitChars(20) }}</a
                    >
                    <feather-icon
                      icon="XIcon"
                      size="18"
                      class="ml-1 cursor-pointer"
                      v-b-tooltip.hover="'Delete file'"
                      @click="deleteFile(file, component, index)"
                    />
                  </b-badge>
                </template>
              </div>
            </div>
            <validation-provider
              v-slot="{ errors }"
              :rules="buildRules(component)"
              class="flex-fill"
              tag="div"
              v-if="shouldRenderFormGroup(component)"
            >
              <component
                :id="component.key"
                :key="component.key"
                :is="component.is"
                v-model="component.model"
                :label="component.options != [] ? 'text' : component.label"
                :options="
                  component.type == 'quill_editor'
                    ? { modules: { toolbar: true } }
                    : component.options
                "
                :reduce="(option) => String(option.value)"
                :getOptionKey="(option) => option.value"
                :multiple="component.multiple"
                :type="component.type"
                :placeholder="component.placeholder"
                :date-format-options="
                  component.type == 'date'
                    ? {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }
                    : false
                "
                :files-array="component.type == 'drag_and_drop' ? [] : false"
                :switch="component.type == 'switch' ? true : false"
                :class="{
                  'custom-control-primary': component.type === 'switch',
                  'border-danger rounded': errors[0],
                  'disabled-field': component.disabled,
                  dark: isDarkSkin,
                }"
                :name="component.type == 'switch' ? 'check-button' : ''"
                :value="
                  component.type == 'switch' ? component.options[0].value : ''
                "
                :unchecked-value="0"
                :source="'drag-and-drop-' + index"
                :state="errors[0] ? false : null"
                @input="componentAcctions(component)"
                :disabled="component.disabled"
              />
              <span v-if="errors[0]" class="text-danger"
                >{{ component.label }} {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>

          <validation-provider
            v-slot="{ errors }"
            :rules="buildRules(component)"
            class="flex-fill"
            tag="div"
            v-if="component.type == 'money'"
          >
            <b-form-group>
              <money
                v-bind="{
                  maxlength: 16,
                  decimal: '.',
                  thousands: ',',
                  prefix: component.format + ' ',
                  precision: 2,
                  min: 0.0,
                  masked: false,
                }"
                class="form-control"
                :class="{
                  'disabled-field': component.disabled,
                }"
                :state="errors[0] ? false : null"
                v-model="component.model"
                :disabled="component.disabled"
              />
              <span v-if="errors[0]" class="text-danger"
                >{{ component.label }} {{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="buildRules(component)"
            class="flex-fill"
            tag="div"
            v-if="component.type == 'text' && component.format"
          >
            <b-form-group>
              <b-form-input
                :placeholder="component.placeholder"
                :masked="component.format ? true : false"
                v-mask="component.format"
                :state="errors[0] ? false : null"
                v-model="component.model"
                :disabled="component.disabled"
              />
              <span v-if="errors[0]" class="text-danger"
                >{{ component.label }} {{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="buildRules(component)"
            class="flex-fill"
            tag="div"
            v-if="component.type == 'address'"
          >
            <b-form-group>
              <VueGoogleAutocomplete
                :id="component.key"
                :ref="component.key"
                classname="input-form form-control fond-white"
                :placeholder="component.placeholder"
                country="us"
                style="height: 35px !important"
                :state="errors[0] ? false : null"
                v-model="component.model"
                v-on:placechanged="
                  (address, placeResultData) =>
                    getAddressData(address, placeResultData, component)
                "
                maxlength="100"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                :disabled="component.disabled"
              />
              <span v-if="errors[0]" class="text-danger"
                >{{ component.label }} {{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="component.model.length > 0 ? '' : buildRules(component)"
            class="flex-fill"
            tag="div"
            v-if="component.type == 'checkbox'"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                :id="component.key"
                v-model="component.model"
                :options="component.options"
                :aria-describedby="ariaDescribedby"
                :name="component.key"
                :state="errors[0] && component.model.length < 1 ? false : null"
                :disabled="component.disabled"
              ></b-form-checkbox-group>
            </b-form-group>
            <span
              v-if="errors[0] && component.model.length < 1"
              class="text-danger"
              >{{ component.label }} {{ errors[0] }}
            </span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="!Boolean(component.model) ? buildRules(component) : ''"
            class="flex-fill"
            v-if="component.type == 'radio'"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                :id="component.key"
                v-model="component.model"
                :options="component.options"
                :aria-describedby="ariaDescribedby"
                :name="component.key"
                :state="errors[0] && !Boolean(component.model) ? false : null"
                @input="componentAcctions(component)"
                :class="
                  errors[0] && !Boolean(component.model) ? 'radio--error' : ''
                "
                :disabled="component.disabled"
              ></b-form-radio-group>
            </b-form-group>
            <span
              v-if="errors[0] && !Boolean(component.model)"
              class="text-danger"
              >{{ component.label }} {{ errors[0] }}
            </span>
          </validation-provider>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import FORM_SERVICE from "@/views/management/views/settings/views/dynamic_forms/services/Form.service.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  props: {
    slug: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    group_id: {
      type: Number,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    returnComponent: {
      type: Array,
      required: false,
    },
  },
  components: {
    DragAndDrop,
    quillEditor,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      sections: [],
      validations: [],
      deletedComponents: [],
    };
  },
  async mounted() {
    await this.GET_DATA_EDIT_CUSTON_FORM();
    await this.processComponentValidations();
  },
  methods: {
    async GET_DATA_EDIT_CUSTON_FORM() {
      try {
        this.addPreloader();
        const { data } = await FORM_SERVICE.GET_DATA_EDIT_CUSTON_COMPONENT({
          slug: this.slug,
          form: this.id,
          group: this.group_id,
          country: this.country,
          is_removed: 0
        });
        if (data.length < 1) return;
        let forms = JSON.parse(data[0].data);
        this.sections = forms;
        forms.forEach((section) => {
          section.components.forEach((component) => {
            component.disabled = !!component.disabled;
            this.validations.push({ ...component });
          });
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async processComponentValidations() {
      this.sections.forEach((section) => {
        section.components.forEach((component) => {
          //only components  with conditions != drag_and_drop
          if (
            component.parent.length > 0 &&
            component.model == null &&
            component.validations.length > 0 &&
            component.type != "drag_and_drop"
          ) {
            component.validations = [];
          }
          //only components drag and drop with validations
          if (
            component.type == "drag_and_drop" &&
            component.validations.length > 0
          ) {
            if (component.old_model && component.parent.length == 0) {
              const oldModel = component.old_model;
              JSON.parse(oldModel).length > 0
                ? (component.validations = [])
                : "";
            } else if (
              component.parent.length > 0 &&
              component.validations.length > 0
            ) {
              component.validations = [];
            }
          }
          //executy event componentAcctions isParents
          if (component.parent.length == 0 && component.model != null) {
            this.componentAcctions(component);
          }
        });
      });
    },
    shouldRenderFormGroup(component) {
      return (
        component.type !== "money" &&
        component.format == false &&
        component.type !== "address" &&
        component.type !== "checkbox" &&
        component.type !== "radio"
      );
    },
    buildRules(component) {
      const typeToRuleMap = {
        email: "email",
        url: "url",
      };
      const resultArray = component.validations.map((item) => {
        if (item.value == null) {
          return item.type;
        } else {
          return `${item.type}:${item.value}`;
        }
      });

      if (typeToRuleMap[component.type]) {
        resultArray.push(typeToRuleMap[component.type]);
      }

      return component.disabled ? null : resultArray.join("|");
    },
    //show and hide components according to the parent component
    shouldShowComponent(component) {
      if (component.parent.length == 0) {
        // If it does not have a parent defined, show the component.
        return true;
      }
      return false;
    },
    //show component if it meets the condition
    componentAcctions(currentComponent) {
      if (currentComponent.type == "drag_and_drop") {
        currentComponent.model.map((file, index) => {
          file["custom_name"] = file.name;
        });
      }

      if (currentComponent.is_parent) {
        this.sections.forEach((section) => {
          section.components.forEach((component) => {
            if (
              component.parent.length > 0 &&
              component.parent[0].key == currentComponent.key
            ) {
              let shouldBreak = false;
              component.parent[0].conditions.forEach((condition) => {
                if (condition.id_option == currentComponent.model) {
                  component.label = condition.label;
                  component.placeholder = condition.placeholder;
                  //asign validations
                  this.validations.forEach((validation) => {
                    if (validation.id == component.id) {
                      //only drag an drop
                      if (component.type == "drag_and_drop") {
                        if (component.old_model) {
                          const oldModel = component.old_model;
                          JSON.parse(oldModel).length < 1
                            ? (component.validations = validation.validations)
                            : "";
                        } else {
                          component.validations = validation.validations;
                        }
                      }
                      //only components != drag_and_drop
                      if (component.type != "drag_and_drop") {
                        component.validations = validation.validations;
                      }
                    }
                  });

                  this.$refs[component.key][0].style.setProperty(
                    "display",
                    "block"
                  );
                  shouldBreak = true;
                }
              });

              if (shouldBreak) {
                return; // get out the loop
              }

              // If shouldBreak is false
              component.label = null;
              component.validations = [];
              this.$refs[component.key][0].style.setProperty(
                "display",
                "none",
                "important"
              );
            }
          });
        });
      }
    },
    getAddressData(data, placeResultData, component) {
      this.$refs[component.key][0].$el.value =
        placeResultData.formatted_address;
      component.model = placeResultData.formatted_address;
    },
    async deleteFile(file, component, index) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to delete this file?"
      );
      if (!confirm.isConfirmed) return;

      component.files.splice(index, 1);
      component.old_model = JSON.stringify(
        JSON.parse(component.old_model).filter(
          (elemento) => elemento.custom_name !== file.custom_name
        )
      );

      //remove or add validations old model is empty
      const oldModel = component.old_model;
      if (JSON.parse(oldModel).length < 1) {
        this.validations.forEach((validation) => {
          if (validation.id == component.id) {
            component.validations = validation.validations;
          }
        });
      }

      this.showToast(
        "success",
        "top-right",
        "Success!",
        "CheckIcon",
        "File deleted successfully"
      );
    },
    async removedComponent(data) {
      try {
        await FORM_SERVICE.removedComponent({
          id: data.id,
          removed: 1
        });
        this.$emit('updateCount')
        this.GET_DATA_EDIT_CUSTON_FORM()
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
  },
  watch: {
    returnComponent(data) {
      this.addComponent(this.sections, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.show-dinamic-forms {
  .ql-container {
    height: 240px !important;
  }
  .radio--error {
    label.custom-control-label {
      color: #fc424a !important;
    }
    label.custom-control-label::before {
      border: 1px solid #fc424a !important;
    }
  }
}
.dark.disabled-field:disabled {
  opacity: 0.5 !important;
  background: transparent !important;
}

.disabled-field:disabled {
  background: #efefef !important;
}

.form-group {
  margin: 0 !important;
}
</style>
