<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-row class="mb-1">
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend style="width: 10%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ addressLabel }}
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                :id="`${id}${index}`"
                :ref="`${id}${index}`"
                :value="address"
                v-model="address"
                classname=" input-form form-control fond-white"
                placeholder="Please type your address"
                :country="country"
                style="height: 35px !important; width: 90%"
                @placechanged="setAddress"
                @change="handleAddress"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="4">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ cityLabel }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                :value="city"
                @input="handleCity"
                style="width: 80%"
              />
            </b-input-group>
          </b-col>

          <b-col md="4">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ stateLabel }}
                </b-input-group-text>
              </b-input-group-prepend>
              <v-select
                :disabled="country!=='us'"
                :value="state"
                @input="handleState"
                label="state"
                :reduce="(val) => val.slug"
                :options="states"
                style="width: 80%"
              />
            </b-input-group>
          </b-col>
          <b-col md="4">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ zipCodeLabel }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                @input="(val) => handleZipCode(val)"
                :value="zipCode"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    vSelect,
    VueGoogleAutocomplete,
  },
  props: {
    addressLabel: {
      type: String,
      default: "Address",
    },
    cityLabel: {
      type: String,
      default: "City",
    },
    stateLabel: {
      type: String,
      default: "State",
    },
    zipCodeLabel: {
      type: String,
      default: "Zip code",
    },
    country: {
      type: String,
      default: "us",
    },
    states: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    address: {
      type: String,
      default: "",
    },
    city: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    zipCode: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "address",
    },
  },
  methods: {
    setAddress(address) {
      const mainAddress = address.street_number
        ? `${address.street_number} ${address.route}`
        : address.route;
      this.$emit("update:address", mainAddress || " ");
      this.$emit("update:city", address.locality || " ");
      this.$emit("update:state", address.administrative_area_level_1 || " ");
      this.$emit("update:zip-code", address.postal_code || " ");
    },
    handleAddress(address) {
      this.$emit("update:address", address);
    },
    handleCity(city) {
      this.$emit("update:city", city);
    },
    handleState(state) {
      this.$emit("update:state", state);
    },
    handleZipCode(zipCode) {
      this.$emit("update:zip-code", zipCode);
    },
  },
};
</script>
<style lang="scss">
</style>