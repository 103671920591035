var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sections[0])?_c('b-row',[_vm._l((_vm.sections[0].components),function(component,index){return [_c('b-col',{key:index,ref:component.key,refInFor:true,staticClass:"mb-1",style:(_vm.shouldShowComponent(component) ? '' : { display: 'none !important' }),attrs:{"cols":"12","sm":"12","md":component.col,"lg":component.col}},[_c('b-form-group',{staticClass:"m-0"},[_c('div',{staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v(_vm._s(component.label))]),_c('div',[_c('feather-icon',{staticClass:"text-danger ml-1 cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){return _vm.removedComponent(component, 0)}}})],1)]),(component.type == 'drag_and_drop')?_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"10px !important"}},[_vm._l((component.files),function(file,index){return [_c('b-badge',{key:index,attrs:{"variant":"light-primary"}},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(file.custom_name),expression:"file.custom_name",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"href":file.fileapproved,"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(_vm._f("limitChars")(file.custom_name,20)))]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Delete file'),expression:"'Delete file'",modifiers:{"hover":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){return _vm.deleteFile(file, component, index)}}})],1)]})],2):_vm._e()]),(_vm.shouldRenderFormGroup(component))?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":_vm.buildRules(component),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(component.is,{key:component.key,tag:"component",class:{
                'custom-control-primary': component.type === 'switch',
                'border-danger rounded': errors[0],
                'disabled-field': component.disabled,
                dark: _vm.isDarkSkin,
              },attrs:{"id":component.key,"label":component.options != [] ? 'text' : component.label,"options":component.type == 'quill_editor'
                  ? { modules: { toolbar: true } }
                  : component.options,"reduce":function (option) { return String(option.value); },"getOptionKey":function (option) { return option.value; },"multiple":component.multiple,"type":component.type,"placeholder":component.placeholder,"date-format-options":component.type == 'date'
                  ? {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }
                  : false,"files-array":component.type == 'drag_and_drop' ? [] : false,"switch":component.type == 'switch' ? true : false,"name":component.type == 'switch' ? 'check-button' : '',"value":component.type == 'switch' ? component.options[0].value : '',"unchecked-value":0,"source":'drag-and-drop-' + index,"state":errors[0] ? false : null,"disabled":component.disabled},on:{"input":function($event){return _vm.componentAcctions(component)}},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e()],1),(component.type == 'money')?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":_vm.buildRules(component),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',[_c('money',_vm._b({staticClass:"form-control",class:{
                'disabled-field': component.disabled,
              },attrs:{"state":errors[0] ? false : null,"disabled":component.disabled},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}},'money',{
                maxlength: 16,
                decimal: '.',
                thousands: ',',
                prefix: component.format + ' ',
                precision: 2,
                min: 0.0,
                masked: false,
              },false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),(component.type == 'text' && component.format)?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":_vm.buildRules(component),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(component.format),expression:"component.format"}],attrs:{"placeholder":component.placeholder,"masked":component.format ? true : false,"state":errors[0] ? false : null,"disabled":component.disabled},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),(component.type == 'address')?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":_vm.buildRules(component),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',[_c('VueGoogleAutocomplete',{ref:component.key,refInFor:true,class:errors[0] ? 'rounded border border-danger' : '',staticStyle:{"height":"35px !important"},style:(errors[0] ? 'border:1px solid red !important;' : ''),attrs:{"id":component.key,"classname":"input-form form-control fond-white","placeholder":component.placeholder,"country":"us","state":errors[0] ? false : null,"maxlength":"100","disabled":component.disabled},on:{"placechanged":function (address, placeResultData) { return _vm.getAddressData(address, placeResultData, component); }},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),(component.type == 'checkbox')?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":component.model.length > 0 ? '' : _vm.buildRules(component),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"id":component.key,"options":component.options,"aria-describedby":ariaDescribedby,"name":component.key,"state":errors[0] && component.model.length < 1 ? false : null,"disabled":component.disabled},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}})]}}],null,true)}),(errors[0] && component.model.length < 1)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e(),(component.type == 'radio')?_c('validation-provider',{staticClass:"flex-fill",attrs:{"rules":!Boolean(component.model) ? _vm.buildRules(component) : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{class:errors[0] && !Boolean(component.model) ? 'radio--error' : '',attrs:{"id":component.key,"options":component.options,"aria-describedby":ariaDescribedby,"name":component.key,"state":errors[0] && !Boolean(component.model) ? false : null,"disabled":component.disabled},on:{"input":function($event){return _vm.componentAcctions(component)}},model:{value:(component.model),callback:function ($$v) {_vm.$set(component, "model", $$v)},expression:"component.model"}})]}}],null,true)}),(errors[0] && !Boolean(component.model))?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(component.label)+" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e()],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }