import { amgApi } from '@/service/axios';

class ServicesForms {
    async getFormsDynamics(params) {
        try {
            const data = await amgApi.post("management/custom-forms/search-list-custom-forms", params);
            return data;
        } catch (err) {
            throw err;
        }
    }

    async GET_ALL_TYPE_COMPONENTS() {
        try{
            const data = await amgApi.get("management/custom-forms/get-all-type-component");
            return data;
        }catch(error){
            throw error;
        }
    }

    async GET_VALIDATIONS_BY_COMPONENT_ID(params) {
        try{
            const data = await amgApi.post("management/custom-forms/get-type-validation", params);
            return data;
        }catch(error){
            throw error;
        }
    }

    async GET_CUSTOM_FORM_BY_ID(params) {
        try{
            const data = await amgApi.post("management/custom-forms/get-custom-form", params);
            return data;
        }catch(error){
            throw error;
        }
    }

    async SAVE_CUSTOM_FORM(params) {
        try{
            const data = await amgApi.post("management/custom-forms/save-custom-form", params);
            return data;
        }catch(error){
            throw error;
        }
    }

    async GET_DATA_EDIT_CUSTON_FORM(params) {
        try{
            const data = await amgApi.post("management/custom-forms/get-data-edit-custom-form", params);
            return data;
        }catch{
            throw error;
        }
    }

    async GET_DATA_EDIT_CUSTON_COMPONENT(params) {
        try{
            const data = await amgApi.post("management/custom-forms/get-data-edit-custom-component", params);
            return data;
        }catch{
            throw error;
        }
    }

    async removedComponent(params) {
      try{
          const data = await amgApi.post("management/custom-forms/removed-component", params);
          return data;
      }catch{
          throw error;
      }
  }
}
export default new ServicesForms();
